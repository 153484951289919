
import { defineComponent } from "vue";
import helpers from "@/helpers/global";
import { ElMessageBox, ElMessage } from "element-plus";

export default defineComponent({
  name: "HelloWorld",
  computed: {
    companyColor() {
      const defaultCompanyColor = "#000000";
      const user = this.$store.getters["user/user"];
      if (user && user.color) {
        return user.color;
      }
      return defaultCompanyColor;
    },
    dataFiltered() {
      const fillter = this.fillterValue;
      const data = JSON.parse(JSON.stringify(this.tableData));

      if (fillter == "candidate_ascending") {
        return data.sort((a: any, b: any) =>
          a.display_name > b.display_name
            ? 1
            : b.display_name > a.display_name
            ? -1
            : 0
        );
      } else if (fillter == "candidate_descending") {
        return data.sort((a: any, b: any) =>
          a.display_name < b.display_name
            ? 1
            : b.display_name < a.display_name
            ? -1
            : 0
        );
      } else if (fillter == "joined_ascending") {
        return data.sort((a: any, b: any) =>
          a.joined_at > b.joined_at ? 1 : b.joined_at > a.joined_at ? -1 : 0
        );
      } else if (fillter == "joined_descending") {
        return data.sort((a: any, b: any) =>
          a.joined_at < b.joined_at ? 1 : b.joined_at < a.joined_at ? -1 : 0
        );
      } else if (fillter == "score_ascending") {
        return data.sort((a: any, b: any) =>
          a.score > b.score ? 1 : b.score > a.score ? -1 : 0
        );
      } else if (fillter == "score_descending") {
        return data.sort((a: any, b: any) =>
          a.score < b.score ? 1 : b.score < a.score ? -1 : 0
        );
      } else if (fillter == "status_ascending") {
        return data.sort((a: any, b: any) =>
          a.status > b.status ? 1 : b.status > a.status ? -1 : 0
        );
      } else if (fillter == "status_descending") {
        return data.sort((a: any, b: any) =>
          a.status < b.status ? 1 : b.status < a.status ? -1 : 0
        );
      } else if (fillter == "rating_ascending") {
        return data.sort((a: any, b: any) =>
          a.rating > b.rating ? 1 : b.rating > a.rating ? -1 : 0
        );
      } else if (fillter == "rating_descending") {
        return data.sort((a: any, b: any) =>
          a.rating < b.rating ? 1 : b.rating < a.rating ? -1 : 0
        );
      } else {
        return data;
      }
    },
    tableData() {
      return this.$store.getters["assessment/candidates"];
    },
    loading() {
      return this.$store.getters["assessmentTemplate/loading"];
    },
  },
  data() {
    return {
      fillterValue: "",
      assessmentsID: "",
    };
  },
  mounted() {
    this.checkPropsId();
  },
  methods: {
    filterActiveClass(value: string) {
      if (this.fillterValue == value) {
        return "active";
      }
      return "";
    },
    format(datetime: string) {
      return helpers.dateFormat(datetime);
    },
    barWidht(progress: number, candidates: number) {
      const pergressPercentage = (progress / candidates) * 100;
      return `width: ${pergressPercentage.toFixed(1)}%`;
    },
    fontColor(progress: number, candidates: number) {
      const pergressPercentage = (progress / candidates) * 100;
      if (+pergressPercentage.toFixed(1) > 50) {
        return "fc-FFFFFF";
      }
      return "text-shadow";
    },
    getLocaleDate(time: string) {
      return helpers.getLocaleDate(time);
    },
    timeAgo(time: string) {
      return helpers.timeAgo(time);
    },
    canReSendInvite(row: any) {
      return row.status != "COMPLETED";
    },
    canClearResults(row: any) {
      // @fixed
      // if (row) return true;
      return row.status != "INVITED";
    },
    reSendInviteCls(row: any) {
      return this.canReSendInvite(row) ? "enabled" : "disabled";
    },
    clearResultsCls(row: any) {
      return this.canClearResults(row) ? "enabled" : "disabled";
    },
    async handleCommand(command: string, row: any, event?: any) {
      const routeId = row.routeId;
      const assessmentId = row._id;
      switch (command) {
        case "edit":
          this.$router.replace(`/assessment/edit/${row.routeId}`);
          break;
        case "clone":
          await this.$store.dispatch(
            "assessmentTemplate/clone",
            {
              routeId: routeId,
            },
            { root: true }
          );
          this.loadTables();
          break;
        case "delete":
          ElMessageBox.confirm(
            (this as any).$t("popup.question.question_box_2.question"),
            (this as any).$t("popup.question.question_box_2.title"),
            {
              confirmButtonText: (this as any).$t("popup.optional.Yes_chi"),
              cancelButtonText: (this as any).$t("popup.optional.Cancel"),
              type: "warning",
            }
          )
            .then(async () => {
              await this.$store.dispatch("assessment/deleteCandidate", row, {
                root: true,
              });
              this.loadTables();
            })
            .catch((error) => {
              if (error == "cancel") return;
              console.error(error);
            });
          break;
        case "resend":
          if (!this.canReSendInvite(row)) {
            event && event.preventDefault();
            break;
          }
          var formTemplate =
            this.$store.getters["assessment/formTemplate"] || {};
          var formDetailsDto = {
            formId: row.form_id,
            publicUrl: row.action_url,
            email: row.email,
            firstname: row.firstname,
            lastname: row.lastname,
            section: Object.values(formTemplate.sections),
          };
          await this.$store.dispatch(
            "assessmentTemplate/invite",
            formDetailsDto,
            {
              root: true,
            }
          );
          ElMessage({
            message: (this as any).$t("popup.message.message_2") + row.email,
            type: "success",
          });
          this.loadTables();
          break;
        case "reset":
          if (!this.canClearResults(row)) {
            event && event.preventDefault();
            break;
          }
          ElMessage({
            message:
              (this as any).$t("popup.message.message_3_1") +
              row.email +
              (this as any).$t("popup.message.message_3_2"),
            type: "success",
          });
          await this.$store.dispatch(
            "assessment/resetCandidate",
            {
              assessmentId: assessmentId,
            },
            {
              root: true,
            }
          );
          var formTemplates =
            this.$store.getters["assessment/formTemplate"] || {};
          var formDetailsDtos = {
            formId: row.form_id,
            publicUrl: row.action_url,
            email: row.email,
            firstname: row.firstname,
            lastname: row.lastname,
            section: Object.values(formTemplates.sections),
            useSameLink: true,
          };
          await this.$store.dispatch(
            "assessmentTemplate/invite",
            formDetailsDtos,
            {
              root: true,
            }
          );
          this.loadTables();
          break;
      }
    },
    async loadCandidates() {
      const formId = this.$router.currentRoute.value.query.id;
      await this.$store.dispatch(
        "assessment/getCandidates",
        { formId: formId },
        { root: true }
      );
      // const formTemplate = this.$store.getters["assessment/formTemplate"];
      // if (_.isObject(formTemplate)) {
      //   this.setHight();
      // }
    },
    loadTables() {
      this.loadCandidates();
      // this.$store.dispatch(
      //   "assessmentTemplate/getList",
      //   { page: 1 },
      //   { root: true }
      // );
    },
    async onClickRating(item: any) {
      // don't neccessary to await
      this.$store.commit("assessment/ignoreLoading", true);
      await this.$store.dispatch("assessment/saveRating", item, { root: true });
      this.$store.commit("assessment/ignoreLoading", false);
      this.loadTables();
    },
    status(status: string) {
      return helpers.workflowStatus(status);
    },
    assessmentsCandidate(row: any) {
      if (row.status != "COMPLETED") return;
      const id = row._id;
      this.$router.push({
        name: "AssessmentsCandidate",
        query: {
          as_id: this.assessmentsID,
          u_id: btoa(encodeURIComponent(id)),
        },
      });
    },
    checkPropsId() {
      const id = this.$router.currentRoute.value.query.id;
      if (id) {
        this.assessmentsID = id + "";
      }
    },
  },
});
