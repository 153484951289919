const valueAndTraitsData = [
  {
    name: "Most Important - 5",
    label_en: "Most Important - 5",
    id: "Most_Important_5",
    percentage: 89,
    value: [
      {
        name: "Excellence",
        label_en: "Excellence",
        id: "Excellence",
        percentage: 60,
        candidate: [
          {
            displayName: "Randy",
            name: "Randy",
            id: "Randyadf3",
            color: "yellow",
          },
        ],
      },
      {
        name: "Empathy",
        label_en: "Empathy",
        id: "Empathy",
        percentage: 40,
        candidate: [
          {
            displayName: "Randy",
            name: "Randy",
            id: "Randyadf3",
            color: "yellow",
          },
        ],
      },
      {
        name: "Integrity",
        label_en: "Integrity",
        id: "Integrity",
        percentage: 40,
        candidate: [
          {
            displayName: "Randy",
            name: "Randy",
            id: "Randyadf3",
            color: "yellow",
          },
        ],
      },
    ],
  },
  {
    name: "Most Important - 5",
    label_en: "Most Important - 5",
    id: "Most_Important_5",
    percentage: 89,
    value: [
      {
        name: "Empathy",
        label_en: "Empathy",
        id: "Empathy",
        percentage: 40,
        candidate: [
          {
            displayName: "Randy",
            name: "Randy",
            id: "Randyadf3",
            color: "green",
          },
        ],
      },
      {
        name: "Empathy",
        label_en: "Empathy",
        id: "Empathy",
        percentage: 40,
        candidate: [
          {
            displayName: "Randy",
            name: "Randy",
            id: "Randyadf3",
            color: "green",
          },
        ],
      },
      {
        name: "Empathy",
        label_en: "Empathy",
        id: "Empathy",
        percentage: 40,
        candidate: [
          {
            displayName: "Randy",
            name: "Randy",
            id: "Randyadf3",
            color: "green",
          },
        ],
      },
    ],
  },
  {
    name: "Most Important - 5",
    label_en: "Most Important - 5",
    id: "Most_Important_5",
    percentage: 89,
    value: [
      {
        name: "Empathy",
        label_en: "Empathy",
        id: "Empathy",
        percentage: 40,
        candidate: [
          {
            displayName: "Randy",
            name: "Randy",
            id: "Randyadf3",
            color: "green",
          },
        ],
      },
      {
        name: "Empathy",
        label_en: "Empathy",
        id: "Empathy",
        percentage: 40,
        candidate: [
          {
            displayName: "Randy",
            name: "Randy",
            id: "Randyadf3",
            color: "green",
          },
        ],
      },
    ],
  },
];

const acceptableData = [
  {
    name: "",
    valuePercentage: 59,
    question:
      "2Frequently showing up a couple of minutes late to meetings and appointments.",
    candidateValue: [
      {
        displayName: "Adela",
        value: "1",
      },
      {
        displayName: "Laury",
        value: "2",
      },
      {
        displayName: "Angus",
        value: "3",
      },
      {
        displayName: "Adela",
        value: "1",
      },
      {
        displayName: "Laury",
        value: "2",
      },
      {
        displayName: "Angus",
        value: "3",
      },
      {
        displayName: "Adela",
        value: "1",
      },
    ],
  },
  {
    name: "",
    valuePercentage: 59,
    question:
      "2Frequently showing up a couple of minutes late to meetings and appointments.",
    candidateValue: [
      {
        displayName: "Adela",
        value: "1",
      },
      {
        displayName: "Laury",
        value: "2",
      },
      {
        displayName: "Angus",
        value: "3",
      },
      {
        displayName: "Adela",
        value: "1",
      },
      {
        displayName: "Laury",
        value: "2",
      },
      {
        displayName: "Angus",
        value: "3",
      },
      {
        displayName: "Adela",
        value: "1",
      },
    ],
  },
  {
    name: "",
    valuePercentage: 59,
    question:
      "2Frequently showing up a couple of minutes late to meetings and appointments.",
    candidateValue: [
      {
        displayName: "Adela",
        value: "1",
      },
      {
        displayName: "Laury",
        value: "2",
      },
      {
        displayName: "Angus",
        value: "3",
      },
      {
        displayName: "Adela",
        value: "1",
      },
      {
        displayName: "Laury",
        value: "2",
      },
      {
        displayName: "Angus",
        value: "3",
      },
      {
        displayName: "Adela",
        value: "1",
      },
    ],
  },
  {
    name: "",
    valuePercentage: 59,
    question:
      "2Frequently showing up a couple of minutes late to meetings and appointments.",
    candidateValue: [
      {
        displayName: "Adela",
        value: "1",
      },
      {
        displayName: "Laury",
        value: "2",
      },
      {
        displayName: "Angus",
        value: "3",
      },
      {
        displayName: "Adela",
        value: "1",
      },
      {
        displayName: "Laury",
        value: "2",
      },
      {
        displayName: "Angus",
        value: "3",
      },
      {
        displayName: "Adela",
        value: "1",
      },
    ],
  },
  {
    name: "",
    valuePercentage: 59,
    question:
      "2Frequently showing up a couple of minutes late to meetings and appointments.",
    candidateValue: [
      {
        displayName: "Adela",
        value: "1",
      },
      {
        displayName: "Laury",
        value: "2",
      },
      {
        displayName: "Angus",
        value: "3",
      },
      {
        displayName: "Adela",
        value: "1",
      },
      {
        displayName: "Laury",
        value: "2",
      },
      {
        displayName: "Angus",
        value: "3",
      },
      {
        displayName: "Adela",
        value: "1",
      },
    ],
  },
];

export { valueAndTraitsData, acceptableData };
